.container {
  position: relative;
  z-index: 1;
  height: 1452px;
  width: 1027px;
}
.container.bg {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(Picture1.png) ;
  background-repeat: no-repeat;
  background-size: 1020px;
  opacity: 0.5;
  
}
.lineImage {
  padding: 0%;
  width: 100%;
}
.lineImageDivider {
  padding: 0%;
  width: 100%;
  height: 2px;
}
img {
  pointer-events: none;
}
.sig{
  position: absolute;
  transform: translate(-50%, -50%);}
  .sigEMP{
    position: absolute;
    transform: translate(-50%, -59%);}
  
    
  
  
  
.tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 8px 5px;
  word-break: normal;
}
.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 8px 5px;
  word-break: normal;
}
.tg .tg-1tm2 {
  border: none;
  font-size: 18px;
  text-align: left;
  text-decoration: underline;
  vertical-align: bottom;
}
.tg .tg-z0zb {
  border: none;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  vertical-align: top;
}
.tg .tg-xymo {
  border: none;
  font-size: 18px;
  text-align: left;
  vertical-align: bottom;
}
.tg .tg-y3sf {
  border: none;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  text-decoration: underline;
  vertical-align: middle;
}
.tg .tg-nwlk {
  border: none;
  font-size: 18px;
  text-align: center;
  text-decoration: underline;
  vertical-align: bottom;
}
.tg .tg-fa7u {
  border: none;
  font-size: 18px;
  text-align: left;
  vertical-align: top;
}
.tg .tg-b420 {
  border: none;
  font-size: 18px;
  text-align: center;
  vertical-align: top;
}
.tg .tg-czk2 {
  border: none;
  font-size: 18px;
  text-align: center;
  vertical-align: bottom;
}

.tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;

  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.tg .tg-yg9r {
  border-color: black;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  vertical-align: top;
}
.tg .tg-zzzz {
  border-color: black;
  border-width: 4px;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  vertical-align: top;
}
.tg .tg-1op2 {
  border-color: black;
  color: #00f;
  font-size: 18px;
  font-weight: bold;

  text-align: center;
  text-decoration: underline;
  vertical-align: middle;
}
.tg .tg-ho95 {
  background-color: #fff;
  border-color: #ffffff;
  font-size: 18px;
  font-weight: bold;

  text-align: left;
  vertical-align: middle;
}
.tg .tg-3vfl {
  background-color: #fff;
  border-color: black;
  font-size: 18px;
  font-weight: bold;

  text-align: left;
  vertical-align: middle;
}
.tg .tg-cji8 {
  border-color: black;
  font-size: 18px;
  font-weight: bold;

  text-align: center;
  vertical-align: middle;
}
.tg .tg-jl80 {
  background-color: #ddebf7;
  border-width: 2px;
  border-color: black;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}
.tg .tg-6ao9 {
  border-color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  vertical-align: middle;
}
.tg .tg-shup {
  border-color: black;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  vertical-align: middle;
}
.tg .tg-u8v2 {
  background-color: #fff;
  border-color: black;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}
.tg .tg-yyyy {
  background-color: #fff;
  border-color: black;
  border-width: 2px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}
.tg .tg-hyun {
  background-color: #fff;
  border-color: black;
  font-size: 18px;
  font-weight: bold;

  text-align: center;
  vertical-align: middle;
}
.tg .tg-arpe {
  border-color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  text-align: right;
  vertical-align: middle;
}
.tg .tg-r5qm {
  border-color: black;
  font-size: 18px;
  font-weight: bold;

  text-align: left;
  vertical-align: middle;
}
.tg .tg-sl9l {
  border-color: #ffffff;
  font-size: 18px;

  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}
.tg .tg-hi3z {
  border-color: #ffffff;
  font-size: 18px;
  font-weight: bold;

  text-align: right;
  vertical-align: middle;
}
.tg .tg-xxxx {
  border-color: #ffffff;
  border-bottom: #000000;

  font-size: 18px;
  font-weight: bold;

  text-align: right;
  vertical-align: middle;
}

.tg .tg-dcx2 {
  border-color: #ffffff;
  font-size: 18px;
  font-weight: bold;

  text-align: left;
  vertical-align: middle;
}
.tg .tg-sdug {
  border-color: #ffffff;
  font-size: 18px;
  font-weight: bold;

  text-align: center;
  vertical-align: middle;
}
.tg .tg-6z68 {
  background-color: #ddebf7;
  border-color: black;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  vertical-align: top;
}
.tg .tg-aqi8 {
  background-color: #fff;
  border-color: black;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  vertical-align: middle;
}
.tg .tg-h52e {
  border-color: black;
  color: #00f;
  font-size: 18px;
  font-weight: bold;

  text-align: center;
  text-decoration: underline;
  vertical-align: top;
}
.tg .tg-7jts {
  border-color: black;
  font-size: 18px;
  font-weight: bold;

  text-align: center;
  vertical-align: top;
}
.tg .tg-hnlb {
  background-color: #fff;
  border-color: black;
  font-size: 18px;
  font-weight: bold;

  text-align: center;
  vertical-align: top;
}
.tg .tg-3avy {
  border-color: #ffffff;
  font-size: 18px;
  font-weight: bold;

  text-align: center;
  text-decoration: underline;
  vertical-align: middle;
}
.tg .tg-38nm {
  border-color: #ffffff;
  color: #00f;
  font-size: 18px;
  font-weight: bold;

  text-align: center;
  text-decoration: underline;
  vertical-align: middle;
}

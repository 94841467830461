.tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 6px 4px;
  word-break: normal;
}
.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 6px 4px;
  word-break: normal;
}
.tg .tg-jqmv {
  border-color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: underline;
  vertical-align: middle;
}
.tg .tg-z4i2 {
  border-color: #ffffff;
  text-align: left;
  vertical-align: middle;
}
.tg .tg-w7c0 {
  border-color: #ffffff;
  font-size: 16px;
  text-align: left;
  vertical-align: middle;
}
.tg .tg-zv4m {
  border-color: #ffffff;
  text-align: left;
  vertical-align: top;
}
.tg .tg-pgku {
  border-color: #ffffff;
  font-size: 16px;
  text-align: center;
  vertical-align: middle;
}
.tg .tg-9ksr {
  border-color: black;
  border-width: 1px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  vertical-align: bottom;
}
.tg .tg-u6id {
  border-color: black;
  border-width: 1px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}
.tg .tg-u6id1 {
  border-color: black;
  border-width: 3px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}
.tg .tg-fmc2 {
  border-color: #ffffff;
  text-align: center;
  vertical-align: bottom;
}
.tg .tg-lh7k {
  border-color: #ffffff;
  text-align: left;
  vertical-align: bottom;
}
.tg .tg-sl9l {
  border-color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}
.tg .tg-2urc {
  background-color: #c0c0c0;
  border-color: black;
  border-left-width: 3px;
  border-top-width: 3px;
  border-right-width: 3px;

  text-align: left;
  vertical-align: middle;
}
.tg .tg-ui32 {
  background-color: #c0c0c0;
  border-color: black;
  border-left-width: 3px;
  border-right-width: 3px;

  font-size: 16px;
  text-align: left;
  vertical-align: middle;
}
.tg .tg-1zvd {
  border-color: black;
  border-width: 3px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  vertical-align: middle;
}
.tg .tg-1zvd0 {
  border-color: black;
  border-top-width: 3px;

  font-size: 16px;
  font-weight: bold;
  text-align: left;
  vertical-align: middle;
}
.tg .tg-ai0l {
  border-color: black;
  border-width: 1px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  vertical-align: top;
}
.tg .tg-f3so {
  background-color: #c0c0c0;
  border-color: black;
  border-left-width: 3px;
  border-right-width: 3px;

  font-size: 16px;
  text-align: left;
  vertical-align: bottom;
}
.tg .tg-f3sox {
  background-color: #c0c0c0;
  border-color: black;
  border-left-width: 3px;
  border-right-width: 3px;
  border-bottom-width: 3px;
  font-size: 16px;
  text-align: left;
  vertical-align: bottom;
}
.tg .tg-9o1m {
  border-color: black;
  border-width: 1px;
  border-top-width: 3px;

  font-size: 16px;
  text-align: center;
  vertical-align: middle;
}
.tg .tg-2776 {
  border-color: black;
  border-width: 1px;

  font-size: 16px;
  text-align: left;
  vertical-align: bottom;
}
.tg .tg-2776x {
  border: none;

  font-size: 16px;
  text-align: left;
  vertical-align: bottom;
}
.tg .tg-2776xy {
  border-color: black;
  border-width: 0;
  border-bottom-width: 3px;
  font-size: 16px;
  text-align: left;
  vertical-align: bottom;
}
.tg .tg-27762 {
  border-color: black;
  border-width: 3px;

  font-size: 16px;
  text-align: left;
  vertical-align: bottom;
}
.tg .tg-27760 {
  border-color: black;
  border-width: 1px;
  border-top-width: 3px;

  font-size: 16px;
  text-align: left;
  vertical-align: bottom;
}
.tg .tg-277601 {
  border-color: black;
  border-width: 3px;

  font-size: 16px;
  text-align: left;
  vertical-align: bottom;
}
.tg .tg-u9r8 {
  border-color: black;
  border-top-width: 3px;

  border-width: 1px;
  font-size: 16px;
  text-align: center;
  vertical-align: bottom;
}
.tg .tg-u9r80 {
  border-color: black;
  border-width: 1px;
  border-top-width: 3px;
  font-size: 16px;
  text-align: center;
  vertical-align: bottom;
}
.tg .tg-u9r81 {
  border-color: black;
  border-width: 1px;
  border-right-width: 3px;
  font-size: 16px;
  text-align: center;
  vertical-align: bottom;
}
.tg .tg-u9r82 {
  border-color: black;
  border-width: 3px;
  font-size: 16px;
  text-align: center;
  vertical-align: bottom;
}
.tg .tg-u9r83 {
  border-color: black;
  border-top-width: 3px;
  border-right-width: 3px;

  font-size: 16px;
  text-align: center;
  vertical-align: bottom;
}
.tg .tg-mlkz {
  border-color: black;
  border-width: 1px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  vertical-align: bottom;
}
.tg .tg-oedm {
  border-color: #ffffff;
  font-size: 16px;
  text-align: left;
  vertical-align: bottom;
}
.tg .tg-0326 {
  border-color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  text-decoration: underline;
  vertical-align: middle;
}
.tg .tg-ttyt {
  border-color: #ffffff;
  font-size: 16px;
  text-align: right;
  vertical-align: middle;
}
.tg .tg-ttytx {
  border-color: black;
  border-width:0 ;
  border-bottom-width: 3px;
  font-size: 16px;
  text-align: right;
  vertical-align: middle;
}
.tg .tg-8srf {
  border-color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  vertical-align: bottom;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 100%;
  /* left: 48%; */
  margin: -10px;
  align-content: center;
}

.ant-layout-sider-trigger {
  background-image: url("./components/layout/AIU_Tringger2.png");
  background-position: "center";
}
.ant-descriptions-item-content {
  color: rgb(31, 29, 29);
}
.ant-menu-item {
  color: #0e335c;
}
.ant-menu-submenu-title {
  color: #0e335c;
}
.ant-menu-item:hover {
  color: #0e335c;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-active .ant-menu-item-selected {
  background-color: #0e335c;
}
.ant-menu-item:hover {
  color: #f27e20;
}
.ant-btn-primary {
  background-color: #0e335c;
  border-color: #0e335c;
}
.ant-menu-submenu-title:hover {
  color: #f27e20;
}
.ant-menu-sub.ant-menu-inline {
  background-color: white;
}

.ant-layout {
  background: #f0f0f7;
}
.ant-form-item-control {
  line-height: 20px;
}

.sigContainer {
    width: 100%;
    height: 100%;
    margin: 1 auto;
    background-color: #fff;
  }
  
  .sigPad {
    width: 100%;
    height: 100%;
  }
  .buttons {
    width: 33.3%;
    height: 30px;
  }

  
  .sigImage {
      margin-top:1%;
    background-size: 20% 20%;
    width: 20%;
    height: 20%;
    background-color: rgb(1, 1, 1, 0);
  }
  
  .containerSig {
      padding-top: 2%;
    width: 60%;
    height: 50vh;
    top: 10%;
    left: 10%;
  }
.draftJsToolbar__button__qi1gf {
  background: #ffffff;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
  padding-left: 2px;
}
